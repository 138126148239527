import { createReducer , on } from '@ngrx/store';
import { App, UsersAsync } from 'src/app/models';
/* models */
/* ngrx */
import * as UsersActions from 'src/app/store/actions/users/users.actions';

export interface UsersState {
    users : UsersAsync[];
}

const initialState : UsersAsync[] = []

export const UsersReducer = createReducer(
    initialState,

    on(UsersActions.getUsersSuccess , (state , {users}  ) => {
        state = users;
        return state;
    }),

    on(UsersActions.addUserSuccess , ( state , { user } ) =>{
        let myState = [...state , user];
        return myState;
    }),
)
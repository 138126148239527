// import { HttpErrorResponse } from '@angular/common/http';
import { createAction , props } from '@ngrx/store';
import { AppRole, RoleToDelete } from 'src/app/models';

export const storeRoles = createAction(
    '[Apps] Store roles',
    props<{ appRoles : AppRole[] }>()
)

export const storeRoleToDelete = createAction(
    '[Apps] Store role to delete',
    props<{ role : RoleToDelete }>()
)

export const cleanRolesState = createAction(
    '[Apps] clean app roles',
    props<{ cleanedState : {} }>()
)


/* export const getAppsRoles = createAction(
    '[Apps] Get apps roles' , 
    props<{ parameters:{appId : string} }>()
);

export const getAppsRolesSuccess = createAction(
    '[Apps] Get apps roles Success',
    props<{ appRoles : AppRole[] }>()
)

export const getAppsRolesFailure = createAction(
    '[Apps] Get apps roles failure',
    props<{ error: HttpErrorResponse }>()
);

export const storeRoleToDelete = createAction(
    '[Apps] Store role to delete',
    props<{ role : RoleToDelete }>()
) */


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PrescriptionResponse } from 'src/app/models';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})

export class PrescriptionsService {

  constructor(
    private http:                                              HttpClient
  ) { }

  LoadPrescriptionById( prescriptionId : string ):Observable<PrescriptionResponse>{

    return this.http.get<PrescriptionResponse>(`${environment.prescriptionsService.url}prescription/${prescriptionId}`);
  }

  SyncProfessionalInDocline( professionalId : string ):Observable<boolean>{

    return this.http.put<boolean>(`${environment.prescriptionsService.url}professionaldocline?professionalId=${professionalId}` , {});
  }
}

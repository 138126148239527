import { createReducer , on } from '@ngrx/store';
import { UserGroup } from 'src/app/models/controlPanel/classes/userGroups.models';
import * as UsersGroupsActions from 'src/app/store/actions/usersGroups/usersGroups.actions';

export interface UsersGroupsState {
    usersGroups : UserGroup[];
}

const initialState : UserGroup[] = [ new UserGroup() ];

export const UsersGroupsReducer = createReducer(
    initialState,

    on(UsersGroupsActions.getUsersGroupsSuccess , (state , {userGroups}  ) => {
        state = userGroups

        console.log(userGroups);
        return state;
    })
)
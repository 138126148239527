<div class="delete-popup_span_container" >
    <span>
        {{ deleteMessage }}
    </span> 
    
</div>

<div class="delete-popup_buttons_container" >

    <div (click)="ConfirmDelete()" >
        <button class="delete-protocol" >Eliminar</button>
    </div>

    <div (click)="CancelDelete()" >
        <button class="delete-popup_button" >Cancelar</button>
    </div>

</div>
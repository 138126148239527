import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[ngModel][appInputTrim]'
})
export class InputTrimDirective {

  @Output() ngModelChange = new EventEmitter<string>(); 
  
  @HostListener('input' , ['$event']) onInputChange($event: KeyboardEvent){
    const currentValue = ($event.target as HTMLInputElement)?.value;
    const trimmedValue = currentValue?.trim();
    this.ngModelChange.emit(trimmedValue);
  }
}

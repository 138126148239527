import { Component , OnInit } from '@angular/core';
import { AppState } from './models';
import { Store } from '@ngrx/store';
import { getApps } from './store/actions/app/app.actions';
import { ComponentToSpinnerService } from './shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'controlPanel';

  constructor(
    private componentToSpinnerService:                        ComponentToSpinnerService,
    private store:                                            Store<AppState>
  ){}

  ngOnInit(): void {
    this.StoreApps();
  }

  StoreApps():void{

    this.componentToSpinnerService.sendMessage('show');

    this.store.dispatch(getApps({ parameters : {} }))
  }

}

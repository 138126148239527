import { Component, EventEmitter, OnInit , OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription , Subject , takeUntil } from 'rxjs';
import { AppState, RoleToDelete } from 'src/app/models';
import { ComponentToDeletePopUpService } from 'src/app/shared/services';

@Component({
  selector: 'app-delete-warning-pop-up-content',
  templateUrl: './delete-warning-pop-up-content.component.html',
  styleUrls: ['./delete-warning-pop-up-content.component.scss']
})
export class DeleteWarningPopUpContentComponent implements OnInit , OnDestroy {

  messageSubscription :                                          Subscription;
  message:                                                       {id:string,name:string,action:string};
  deleteMessage:                                                 string;
  roleToDeleteDestroyed$:                                        Subject<void> = new Subject<void>();

  @Output() CloseDeletePopUp :                                   EventEmitter<string> = new EventEmitter;
  
  constructor(
    private store:                                               Store<AppState>
  ){
  }

  ngOnInit(): void {

    this.store.select( AppState => AppState.RoleToDelete )
    .pipe(takeUntil( this.roleToDeleteDestroyed$ ))
    .subscribe(( roleToDelete : RoleToDelete ) => {

      //console.log( roleToDelete );

      const {roleName } = roleToDelete || {};
      this.deleteMessage = `desea borrar elemento : ${roleName}`
    })
  }

  SetMessage():void{
    this.deleteMessage = `desea borrar elemento : ${this.message.name ? this.message.name : this.message.id}` ;
  }

  ConfirmDelete():void{
    this.CloseDeletePopUp.emit('delete');
  }

  CancelDelete():void{
    this.CloseDeletePopUp.emit('cancel');
  }

  ngOnDestroy(): void {

    this.roleToDeleteDestroyed$.next();
    this.roleToDeleteDestroyed$.complete();

  }

}

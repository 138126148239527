import { Injectable } from '@angular/core';
import { User } from 'src/app/models';

@Injectable()

export class UserSearchTableHelperService {

  AddSelectedAttribute( users : User[] ):User[]{

    return users.map(( u ) => {

      return { ...u , selected : false };
      
    })
  }
}

import {MsalInterceptorConfiguration, MsalGuardConfiguration 
} from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType , LogLevel, PublicClientApplication  } from '@azure/msal-browser';
import { environment } from 'src/environments/environment.development';


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
/************************************************* MSAL DEBUG *******************************************************************/ 
/* export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
  console.log(logLevel);
} */

/************************************************* MSAL INSTANCES FACTORIES ******************************************************/


export function controlPanelMSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.controlPanelService.clientId,
      authority: environment.controlPanelService.authority,
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.logoutRedirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      allowNativeBroker: false,
      allowRedirectInIframe: true,
      loggerOptions: {
        /* loggerCallback, */
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

/****************************************************************** MSAL GUARDS **************************************************/  
export function prescriptionsMSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.controlPanelService.scopes
    },
    loginFailedRoute: '/login-failed'
  };
}

/*********************************************** INTERCEPTORS CONFIG *******************************************************/
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(environment.usersService.url ,         environment.controlPanelService.scopes);
  protectedResourceMap.set(environment.permissionsService.url ,   environment.permissionsService.scopes);
  protectedResourceMap.set(environment.prescriptionsService.url ,   environment.prescriptionsService.scopes);
 
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component , OnDestroy, OnInit } from '@angular/core'
/* fontawsome */
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { 
  fas  , IconDefinition , faLock , faUsers
} from '@fortawesome/free-solid-svg-icons';
/* models */
import { App, AppState,  UserInfo } from 'src/app/models';
import { AuthComponent } from 'src/app/auth/auth.component';
/* ngrx */
import { Store } from '@ngrx/store';
/* rxjs */
import { Subject , takeUntil } from 'rxjs';
import { ComponentToSpinnerService } from '../shared/services';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  providers: [AuthComponent]
})

export class SideNavComponent implements OnInit , OnDestroy {

  mobileQuery:                                                    MediaQueryList;
  faLock:                                                         IconDefinition=faLock;
  faUsers:                                                        IconDefinition=faUsers;
  filterFilledNav:                                                App[] = [];
  fillerNav:                                                      App[] = [];
  userName:                                                       string = '';
  userDestroyed$:                                                 Subject<void> = new Subject<void>();
  appDestroyed$:                                                  Subject<void> = new Subject<void>();
  panelOpenState:                                                 boolean = true;

  private _mobileQueryListener: () => void;

  constructor(

    private componentToSpinnerService:                            ComponentToSpinnerService,
    private store:                                                Store<AppState>,
    private authComponent :                                       AuthComponent,
    changeDetectorRef:                                            ChangeDetectorRef, 
    media:                                                        MediaMatcher,
    library:                                                      FaIconLibrary

  ) {

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    library.addIconPacks(fas);
    library.addIcons(faLock);
    library.addIcons(faUsers);
  }

  ngOnInit(): void {
    this.filterFilledNav = this.fillerNav;
    this.GetApps();
    this.GetUserInfo();
  }

  GetUserInfo():void{
    this.store.select( AppState => AppState.User ).pipe(takeUntil( this.userDestroyed$ )).subscribe(( user : UserInfo ) => { 
      if( user.name )this.userName = user.name.split(' ')[0] 
    })
  }

  GetApps():void{
    this.store.select( AppState => AppState.App ).pipe(takeUntil( this.appDestroyed$ )).subscribe(( Apps : App[] ) => {

      this.componentToSpinnerService.sendMessage('hide');

      let myApps = [ ...Apps ];
      myApps = myApps.map((a) => { return {...a , route:'appActions' , iconUri : a.iconUri? a.iconUri : ''} } )
      this.filterFilledNav = myApps;
      this.fillerNav = myApps;
    })
  }

  FilterApps( e:InputEvent ):void{

    const value = (e.target as HTMLInputElement).value; 

    if(value.length > 0){

      this.filterFilledNav = this.fillerNav.filter((a) => {
              
        if(a.name){
          return a.name.toLowerCase().includes(value.toLowerCase());
        }else{
          return '';
        }
      });

    }else{
      this.filterFilledNav = this.fillerNav;
    }
  }

  Logout():void{
    this.authComponent.Logout();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.userDestroyed$.next();
    this.userDestroyed$.complete();
    this.appDestroyed$.next();
    this.appDestroyed$.complete();
  }
}

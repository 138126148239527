import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, switchMap , map , of, tap } from "rxjs";
import * as UsersGroupsActions from 'src/app/store/actions/usersGroups/usersGroups.actions';
import { UserGroup } from "src/app/models/controlPanel/classes/userGroups.models";
import { HttpErrorResponse } from "@angular/common/http";
import { UsersGroupsService } from "src/app/users-groups/services";

@Injectable()

export class UsersGroupsEffects {
    
    constructor(
        private action$ :                    Actions,
        private usersGroupsService :         UsersGroupsService
    ){}

    loadUsersGroups$ = createEffect(() => {

        return this.action$

        .pipe(

            ofType(UsersGroupsActions.getUsersGroups),

            tap((params) => console.log(params)),

            switchMap(() => {
                
                return this.usersGroupsService.LoadUsersGroups().pipe(

                    map((userGroups : UserGroup[]) => {

                        return UsersGroupsActions.getUsersGroupsSuccess( { userGroups } )

                    }),

                    catchError((error:HttpErrorResponse) => of())
                )
            })
        )
    })
}
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentToDeletePopUpService {

  private subject = new Subject<{name ? :string , id ? :string , action ? :string}>();

  sendMessage( message : {name:string , id:string , action:string} ) {
    this.subject.next(message);
  }

  clearMessages() {
    this.subject.next({});
  }

  onMessage(): Observable<{name ? :string , id ? :string , action ? :string}> {
    return this.subject.asObservable();
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
  
  currentApplication :                                       string | null = '';
  applicationId:                                             string | null = '';
  objectId:                                                  string | null = '';

  constructor(
    private route:                                           ActivatedRoute,
  ){}

  ngOnInit(): void {
    this.CheckParams();
  }

  CheckParams():void{

    this.route.queryParamMap.subscribe(queryParam => {

      this.currentApplication = queryParam.get('currentApplication'); 
      this.objectId = queryParam.get('objectId');
      this.applicationId = queryParam.get('applicationId');
    });
  }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserGroup } from 'src/app/models/controlPanel/classes/userGroups.models';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})

export class UsersGroupsService {

  constructor(
    private http:                                         HttpClient
  ) { }

  LoadUsersGroups():Observable<UserGroup[]>{
    return this.http.get<UserGroup[]>(`${environment.usersService.url}groups`);
  }
}

import { createReducer , on } from '@ngrx/store';
import { App } from 'src/app/models';
/* models */
/* ngrx */
import * as appsActions from 'src/app/store/actions/app/app.actions';

export interface AppsState {
    apps : App[];
}

const initialState : App[] = []

export const AppsReducer = createReducer(
    initialState,
    
    on( appsActions.getAppsSuccess , (state , {apps}  ) => {
        
        return apps;
    }),

    on(appsActions.getAppsFailure , (state , {error}  ) => {
        
       return [error]
        
    })
)






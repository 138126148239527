import { appsEffects } from "./effects/apps/apps.effects";
import { UsersInAppsEffects } from "./effects/permissions/permissions.effects";
import { PrescriptionsEffects } from "./effects/searcher/prescriptions.effects";
import { StatsEffects } from "./effects/stats/prescriptionsStats.effects";
import { UsersEffects } from "./effects/users/users.effects";
import { UsersGroupsEffects } from "./effects/usersGroups/usersGroups.effects";

export const metaEffects = [
    appsEffects,
    UsersInAppsEffects,
    UsersEffects,
    StatsEffects,
    UsersGroupsEffects,
    PrescriptionsEffects
]
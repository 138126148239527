import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { PrescriptionResponse, PrescriptionsStats } from "src/app/models";

export const getPrescriptionById = createAction(
    '[prescriptionsSearch] Get prescriptions by Id' , 
    props<{ prescriptionId : string }>()
);

export const getPrescriptionByIdSuccess = createAction(
    '[prescriptionsSearch] Get prescriptions by Id success' , 
    props<{ prescription : PrescriptionResponse }>()
);

export const getPrescriptionByIdFailure = createAction(
    '[prescriptionsSearch] Get prescriptions by Id failure' , 
    props<{ error : HttpErrorResponse }>()
);

export const cleanPrescriptionById = createAction(
    '[prescriptionsSearch] Get prescriptions by Id Clean' , 
    props<{ order : string }>()
);
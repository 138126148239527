import { HttpErrorResponse } from '@angular/common/http';
import { createAction , props } from '@ngrx/store';
import { App } from 'src/app/models';


export const getApps = createAction(
    '[Apps] Get apps' , 
    props<{ parameters:object }>()
);

export const getAppsSuccess = createAction(
    '[Apps] Get apps Success',
    props<{ apps : App[] }>()
)

export const getAppsFailure = createAction(
    '[Apps] Get apps failure',
    props<{ error: HttpErrorResponse }>()
);


import { Injectable } from '@angular/core';
/* rxjs */
import { of } from 'rxjs';
import { catchError , exhaustMap, map, switchMap } from 'rxjs/operators';

/* ngrx */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as appActions from 'src/app/store/actions/app/app.actions';
import { AppsService } from 'src/app/app-actions/services';

@Injectable()

export class appsEffects {

    constructor( 
        private action$ : Actions,
        private appsService : AppsService
    ){}

    LoadApps$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(appActions.getApps),
            switchMap(/* action */ () =>
                {
                    
                    return this.appsService.LoadApps( /* action.parameters */ )
                    .pipe(
                        map(apps => { return appActions.getAppsSuccess ({ apps }) }),
                        
                        catchError(error => {
                            
                            return of( appActions.getAppsFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })
}

import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { AppState } from "../models/appState.models";
import { UserReducer } from "./reducers/users/user.reducers";
import { hydrationMetaReducer } from "./hydratation.reducer";
import { AppsReducer } from "./reducers/apps/apps.reducers";
import { AppsRolesReducer } from "./reducers/apps/appRoles.reducers";
import { RoleToDeleteReducer } from "./reducers/apps/roleToDelete.reducers";
import { UsersReducer } from "./reducers/users/users.reducers";
import { PermissionsUserPerAppReducer } from "./reducers/permissions/permissions.reducers";
import { PermissionsAppsPerAppReducer } from "./reducers/permissions/permissionsForApps.reducers";
import { PrescriptionsStatsReducer } from "./reducers/stats/prescriptonsStats.reducers";
import { UsersGroupsReducer } from "./reducers/usersGroups/usersGroups.reducers";
import { UsersGroupsFiltersReducer } from "./reducers/usersGroups/usersGroupsFilters.reducers";
import { PrescriptionReducer } from "./reducers/searcher/prescriptions.reducers";


export const reducers : ActionReducerMap<AppState> = {
  
  User : UserReducer,
  App  : AppsReducer,
  UsersAppRoles : PermissionsUserPerAppReducer,
  AppsAppRoles : PermissionsAppsPerAppReducer,
  AppRoles: AppsRolesReducer,
  RoleToDelete: RoleToDeleteReducer,
  UsersAsync : UsersReducer,
  UsersGroups: UsersGroupsReducer,
  UsersGroupsFilters: UsersGroupsFiltersReducer,
  PrescriptionsStats :PrescriptionsStatsReducer,
  PrescriptionSearch : PrescriptionReducer
}

export function debug(reducer: ActionReducer<string>): ActionReducer<string> {
  return function(state, action) {
    
 
    return reducer(state, action);
  };
}

export const metaReducers : MetaReducer[] = [hydrationMetaReducer]
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionsStatsService {

  constructor(
    private http :                                            HttpClient
  ) { }

  LoadPrescriptionsMetrics( parameters : { from:string , to:string } ):Observable<any>{

    const { from , to } = parameters || {};

    return this.http.get(`${environment.prescriptionsService.url}stats?from=${from}&to=${to}`);
  }
}

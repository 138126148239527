import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { RouterLink, RouterModule } from '@angular/router';
/* devexpress */
import { DxButtonModule, DxLoadIndicatorModule, DxTemplateModule } from 'devextreme-angular';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { DeleteWarningPopUpContentComponent } from './components/delete-warning-pop-up-content/delete-warning-pop-up-content.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTrimDirective } from 'src/app/shared/directives';
import { AlertComponent } from './components/alert/alert.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FilterInputComponent } from './components/inputs/filter-input/filter-input.component';



@NgModule({

  declarations: [
    TopMenuComponent,
    SpinnerComponent,
    DeleteWarningPopUpContentComponent,
    /* directives */
    InputTrimDirective,
    AlertComponent,
    FilterInputComponent
  ],

  imports: [
    CommonModule,
    RouterLink,
    RouterModule,
    DxButtonModule,
    DxLoadIndicatorModule,
    DxTemplateModule,
    /* forms */
    FormsModule,
    ReactiveFormsModule,
    /* fontawesome */
    FontAwesomeModule
  ],

  exports:[
    TopMenuComponent,
    SpinnerComponent,
    DeleteWarningPopUpContentComponent,
    InputTrimDirective,
    AlertComponent,
    FilterInputComponent
  ]

})

export class SharedModule { }

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, switchMap , map , mergeMap, of, tap } from "rxjs";
import * as UsersActions from 'src/app/store/actions/users/users.actions';
import { UsersService } from "src/app/app-actions/services";
import { UsersAsync } from "src/app/models";

@Injectable()

export class UsersEffects {
    
    constructor(
        private action$ :                    Actions,
        private usersService :               UsersService
    ){}

    loadUsers$ = createEffect(() => {

        return this.action$

        .pipe(

            ofType(UsersActions.getUsersFiltered),

            tap((params) => console.log(params)),

            switchMap((action) => {
                
                return this.usersService.LoadUsers(action.term).pipe(

                    map((users : UsersAsync[]) => {

                        return UsersActions.getUsersSuccess( { users } )

                    }),

                    catchError((error:any) => of())
                )
            })
        )
    })

    addUser$ = createEffect(() => {
        
        return this.action$

        .pipe(

            ofType(UsersActions.addUser),

            mergeMap(( action ) => {

                return this.usersService.AddNewUser( action.user )

                .pipe(

                    map(( data ) =>{
                        
                        let { user } = action;

                        user = {...user , principalId : data};

                        return UsersActions.addUserSuccess({ user });
                    })
                )
            })
        )
    })
}
/* export class UserGroupsResponse {
    "@odata.context"=                        null;
    "@odata.count":                          number = 0;
    userGroup:                               UserGroup[] = [];
    stateStatus=                             'first_assignement'
} */

export class UserGroup {
    id=                                      "";
    displayName=                             "";
    description=                             null;
    expirationDateTime:                      Date = new Date();
    mail=                                    "";
    stateStatus=                             'first_assignement';
}
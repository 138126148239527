import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { App, AppRole, RoleToDelete,UsersAppRoles, UsersAppRolesMultiple } from 'src/app/models';
import { RoleToAdd } from 'src/app/models/controlPanel/interfaces/appRoles.models';

@Injectable({
  providedIn: 'root'
})
export class AppsService {

  constructor(
    private http :                                            HttpClient
  ) { }

  LoadApps():Observable<App[]>{

    return this.http.get<App[]>(`${environment.permissionsService.url}permissions/apps`);
  }

  LoadAppById( appId : string ){
    return this.http.get(`${environment.permissionsService.url}permissions/apps/${appId}`);
  }

  LoadRolesByAppId( appId : string ):Observable<AppRole[]>{
    return this.http.get<AppRole[]>(`${environment.permissionsService.url}permissions/apps/${appId}/roles`);
  }

  /* request inside a loop in roleIdsArray */
  LoadUsersByAppIdAndRoleId( parameters : { appId : string , roleId : string } ):Observable<UsersAppRoles>{
    
    const { appId , roleId } = parameters || {};
    return this.http.get<UsersAppRoles>(`${environment.permissionsService.url}permissions/apps/${appId}/roles/${roleId}`);
  }

  /* unique request giving roleIds array as param */
  LoadUsersByAppIdAndRoleIdMultiple( appId : string , roleIds : string[] ):Observable<UsersAppRolesMultiple[]>{
    
    let rolesIdsStr = ``; 

    for (let i = 0; i < roleIds.length; i++) {
      
      rolesIdsStr += `roleIds=${roleIds[i]}&`;
      
    }

    if(rolesIdsStr.slice(-1) === '&')rolesIdsStr = rolesIdsStr.replace(/.$/,"");

    return this.http.get<UsersAppRolesMultiple[]>(`${environment.permissionsService.url}permissions/apps/${appId}/roles/multiple?${rolesIdsStr}`);
  }

  RemovePermissionInApp( roleToDelete : RoleToDelete ){
    const { objectId , roleId , principalId } = roleToDelete || {};
    return this.http.delete(`${environment.permissionsService.url}permissions/apps/${objectId}/roles/${roleId}/user/${principalId}`);
  }

  AddPermissionToAUserInApp( roleToAdd : RoleToAdd ){
    const { objectId , roleId , principalId } = roleToAdd || {};
    return this.http.post(`${environment.permissionsService.url}permissions/apps/${objectId}/roles/${roleId}/user/${principalId}` , {});
  }

  
}

import { Injectable } from '@angular/core';
import { UsersAppRolesMultiple } from 'src/app/models';
import { ApplicationAppRole, UserAppRole } from 'src/app/models/controlPanel/classes/usersAppRolesMultiple.models';

@Injectable()

export class PermissionsTableHelperService {

  /****************************************************** FOR USERS **************************************************************/

  AddRoleDataToUsersObject( usersAppRolesMultiple : UsersAppRolesMultiple[] ) :UserAppRole[] {

    const myUsersToListRolesApps : UserAppRole[] = []; 

    for (let i = 0; i < usersAppRolesMultiple.length; i++) {
      
      const { role : { id , name } } = usersAppRolesMultiple[i] || {};

      for (let x = 0; x < usersAppRolesMultiple[i].userRoleAssignments.length; x++) {
        
        myUsersToListRolesApps.push({ ...usersAppRolesMultiple[i].userRoleAssignments[x].user , role : { roleId : id , roleName : name } });
        
      }
      
    }

    return myUsersToListRolesApps;
  }

  GroupRolesByUser( users : UserAppRole[] ):UserAppRole[]{
    
    const roleIdMap = new Map();

    users.forEach(user => {

      const { principalId, role : {roleId} } = user;

      if (!roleIdMap.has(principalId)) {

        roleIdMap.set(principalId, []);

      }

      roleIdMap.get(principalId).push(roleId);
      
    });

    users.forEach(user => {

      const { principalId } = user;

      user.roles = roleIdMap.get(principalId);

    });

    const usersUnique: UserAppRole[] = Array.from(new Set(users.map(user => user.principalId))).map(principalId => {

        const user = users.find(u => u.principalId === principalId);

        const roles = roleIdMap.get(principalId)?.map((roleId : string ) => {

          const matchingUser = users.find(u => u.role.roleId === roleId);

          if (matchingUser) {

            return { roleId, roleName: matchingUser.role.roleName };

          }
          return null;

        }).filter(Boolean) as { roleId: string; roleName: string }[];

        return { ...user!, roles: roles };
    });

    return usersUnique;
  }


  /****************************************************** FOR APPS **************************************************************/
  AddRoleDataToAppsObject( appsAppRolesMultiple : UsersAppRolesMultiple[] ):ApplicationAppRole[]{
    
    const myAppsToListRolesApps : ApplicationAppRole[] = []; 

    for (let i = 0; i < appsAppRolesMultiple.length; i++) {
      
      const { role : { id , name } } = appsAppRolesMultiple[i] || {};

      for (let x = 0; x < appsAppRolesMultiple[i].applicationRoleAssignments.length; x++) {

        myAppsToListRolesApps.push({ ...appsAppRolesMultiple[i].applicationRoleAssignments[x].application , role : { roleId : id , roleName : name } });
        
      }
      
    }

    return myAppsToListRolesApps;
  }

  GroupRolesByApp( apps : ApplicationAppRole[] ):ApplicationAppRole[]{
    
    const roleIdMap = new Map();

    apps.forEach(app => {

      const { appId, role : {roleId} } = app;

      if (!roleIdMap.has(appId)) {

        roleIdMap.set(appId, []);

      }

      roleIdMap.get(appId).push(roleId);
      
    });

    apps.forEach(app => {

      const { appId } = app;

      app.roles = roleIdMap.get(appId);

    });

    const appsUnique: ApplicationAppRole[] = Array.from(new Set(apps.map(app => app.appId))).map(appId => {

        const app = apps.find(a => a.appId === appId);

        const roles = roleIdMap.get(appId)?.map((roleId : string ) => {

          const matchingApp = apps.find(a => a.role.roleId === roleId);

          if (matchingApp) {

            return { roleId, roleName: matchingApp.role.roleName };

          }
          return null;

        }).filter(Boolean) as { roleId: string; roleName: string }[];

        return { ...app!, roles: roles };
    });

    return appsUnique;
  }
}

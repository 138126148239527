import { HttpErrorResponse } from "@angular/common/http";

export class PrescriptionsStats {
    status? :                                    string = 'initial';
    error ? :                                    HttpErrorResponse;
    from:                                        Date = new Date();
    to:                                          Date = new Date();
    total:                                       number = 0;
    statsGroups:                                 StatsGroup[] = [ /* new StatsGroup() */ ];
}

export class StatsGroup {
    status:                                      string = "";
    total:                                       number = 0;
    dailyValues:                                 { [key: string]: number } = {};
}
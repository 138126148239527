export interface PermissionsAPIExceptions {
    headers:    Headers;
    status:     number;
    statusText: string;
    url:        string;
    ok:         boolean;
    name:       string;
    message:    string;
    error:      Error;
}

export interface Error {
    Message:        string;
    Errors:         null;
    Type:           string;
    HttpStatusCode: number;
}

export interface Headers {
    normalizedNames: NormalizedNames;
    lazyUpdate:      null;
}

export interface NormalizedNames {
}

export class ErrorTypeTranslated {
    "Resource AlreadyExists" = 'Role o Aplicación ya existentes';
    "Resource NotFound" = 'Role o Aplicación no encontrada';
    "Validation Exception" = 'Solicitud incorrecta';
    "Unknown Exception" = 'Error Desconocido';
}
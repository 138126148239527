<div class="example-container" [class.example-is-mobile]="mobileQuery.matches" >
  <mat-toolbar color="primary" class="example-toolbar">
      <div class="side_menu-top_menu_container" >
        <!-- <div>
          <button class="sidenav-toggle-button" mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
        </div> -->
        <div>
          <h1 class="example-app-name">Control Panel</h1>
        </div>
      </div>

      <div>
        <div>
          <span class="side_menu-userName_span" >{{ userName }}</span>
          <fa-icon class="side_menu-logout_icon" (click)="Logout()" [icon]="faLock"></fa-icon>
        </div>
      </div>
      
    
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container"
      [style.marginTop.px]="mobileQuery.matches ? 56 : 0">

    <mat-sidenav opened="true" #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">

      <mat-nav-list>
        <div class="side_menu-menu_title_container">
          <span class="side_menu-menu_title" >
            Usuarios
          </span>
        </div>

        <div>
          <div class="side_menu-users_item" >
            <fa-icon class="side_menu-users_icon" [icon]="faUsers"></fa-icon>
            <a 
              mat-list-item 
              [routerLink]="'users'"
            > Usuarios </a>
          </div>

        </div>

      </mat-nav-list>

      <mat-nav-list>
        <div class="side_menu-menu_title_container">
          <span class="side_menu-menu_title" >
            Grupo de usuarios
          </span>
        </div>

        <div>
          <div class="side_menu-users_item" >
            <fa-icon class="side_menu-users_icon" [icon]="faUsers"></fa-icon>
            <a 
              mat-list-item 
              [routerLink]="'usersGroups'"
            > Grupo de Usuarios </a>
          </div>

        </div>

      </mat-nav-list>

      <mat-nav-list> 

        <mat-accordion>
          
          <mat-expansion-panel (opened)="panelOpenState = true"
            (closed)="panelOpenState = false" [expanded]="true"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="side_menu-Apps_dropdown_title" >
                Apps
              </mat-panel-title>
              <mat-panel-description>
                Administra permisos 
              </mat-panel-description>
            </mat-expansion-panel-header>
            
            <div class="side_nav-search_input_container" >
              <input 
                type="search"
                (input)='FilterApps($event)'  
                class="input_nosubmit_white side_nav-search_input"
                placeholder="Buscar aplicación"
              >
            </div>
    
            <div class="side_menu-items_container">
              <div *ngFor="let nav of filterFilledNav" >
    
                <div class="side_menu-item_main_container" >
      
                  <div class="side_menu-img_container" >
      
                    <img 
                      class="side_menu-item_icon" 
                      [ngSrc]="nav.iconUri || 'https://google.com'" 
                      alt="" 
                      width="36" 
                      height="36"
                    >
      
                  </div>
      
                  <div class="side_menu-span_item_name_container" >
      
                    <a 
                      mat-list-item 
                      [routerLink]="nav.route" 
                      [queryParams]="{action:'menuOrder',currentApplication: nav.name , applicationId:nav.appId , objectId:nav.resourceId}"
                    > {{nav.name}} </a>
      
                  </div>
      
                </div>
      
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
        
      </mat-nav-list>

      
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>

  </mat-sidenav-container>
</div>
  
 
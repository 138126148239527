import { HttpErrorResponse } from "@angular/common/http";

export class PrescriptionResponse {
    id=                                                 "";
    doclineId=                                          "";
    professionalId=                                     "";
    professional:                                       Professional = new Professional;
    professionalDocline:                                Docline = new Docline;
    patientId=                                          "";
    patient:                                            Patient = new Patient;
    patientDocline:                                     Docline = new Docline;
    isConfidential=                                     false;
    status=                                             "";
    medicaments:                                        Medicament[] = [ new Medicament() ];
    masterFormulas:                                     MasterFormula[] = [ new MasterFormula ];
    parapharmacyMedicaments:                            ParapharmacyMedicament[] = [ new ParapharmacyMedicament ];
    createdOn:                                          Date = new Date();
    lastPrintDate:                                      Date = new Date();
    friendlyId=                                         "";
    error?:                                             HttpErrorResponse;
}

export class Medicament {
    id=                                                 "";
    name=                                               "";
    takingAmount=                                       0;
    packagesAmount=                                     0;
    frequencyAmount=                                    0;
    frequencyUnit=                                      "";
    durationAmount=                                     0;
    durationUnit=                                       "";
    status=                                             "";
    patientInstructions=                                "";
    pharmacistWarnings=                                 "";
    narcotic=                                           false;
    psychotropic=                                       false;
    startDate:                                          Date = new Date();
    endDate:                                            Date = new Date();
    code=                                               "";
    unit:                                               AdministrationWay = new AdministrationWay();
    pharmaForm:                                         AdministrationWay = new AdministrationWay();
    administrationWay:                                  AdministrationWay = new AdministrationWay();
    unitAmount=                                         0;
    fullName=                                           "";
}

export class MasterFormula {
    id=                                                 "";
    name=                                               "";
    takingAmount=                                       0;
    packagesAmount=                                     0;
    frequencyAmount=                                    0;
    frequencyUnit=                                      "";
    durationAmount=                                     0;
    durationUnit=                                       "";
    status=                                             "";
    patientInstructions=                                "";
    pharmacistWarnings=                                 "";
    narcotic=                                           false;
    psychotropic=                                       false;
    startDate:                                          Date = new Date();
    endDate:                                            Date = new Date();
    prescriptionId=                                     "";
    doclineId=                                          "";
    composition=                                        "";
    dosage=                                             "";
    takingUnit=                                         "";
    pharmaceuticalForm=                                 "";
    administrationWay=                                  "";
}

export class AdministrationWay {
    id=                                                 0;
    name=                                               "";
}

export class ParapharmacyMedicament {
    id=                                                 "";
    name=                                               "";
    posology=                                           "";
    status=                                             "";
    patientInstructions=                                "";
    startDate:                                          Date = new Date();
    endDate:                                            Date = new Date();
}

export class Patient {
    name=                                               "";
    firstName=                                          "";
    lastName=                                           "";
    secondLastName=                                     "";
    identityCard=                                       "";
    gender=                                             "";
    birthDate=                                          Date;
    profession=                                         "";
    address=                                            "";
    city=                                               "";
    province=                                           "";
    postalCode=                                         "";
    country=                                            "";
    phoneNumbers:                                       string[] = [""];
    emails:                                             string[] = [""];
    id=                                                 "";
    type=                                               "";
    tutor:                                              Tutor = new Tutor();
    clinics:                                            ClinicElement[] = [ new ClinicElement() ];
    isActive=                                           false;
    isMerged=                                           false;
    lastUpdateDate:                                     Date = new Date();
    lastUpdateUser=                                     "";
}

export class ClinicElement {
    type=                                               "string";
    clinicCode=                                         "string";
    clinicName=                                         "string";
    parentClinicCode=                                   "string";
    parentClinicName=                                   "string";
    patientKey=                                         "string";
    defaulter=                                          false;
    visitReasonCode=                                    "string";
    observations:                                       string[] = [""];
}

export class Tutor {
    name=                                               "";
    firstName=                                          "";
    lastName=                                           "";
    secondLastName=                                     "";
    identityCard=                                       "";
    gender=                                             "";
    birthDate:                                          Date = new Date();
    profession=                                         "";
    address=                                            "";
    city=                                               "";
    province=                                           "";
    postalCode=                                         "";
    country=                                            "";
    phoneNumbers:                                       string[] = [""];
    emails:                                             string[] = [""];
}

export class Docline {
    patientId?=                                         "";
    doclineId=                                          "";
    isValid=                                            false;
    validationErrors:                                   string[] = [""];
    professionalId?=                                    "";
}

export class Professional {
    id=                                                 "";
    ojoId=                                              "";
    userCode=                                           "";
    professionalClinics:                                ProfessionalClinic[] = [ new ProfessionalClinic ];
    firstName=                                          "";
    lastName=                                           "";
    email=                                              "";
    documentNumber=                                     "";
    phoneNumber=                                        "";
    gender=                                             "";
    active=                                             false;
    role=                                               "";
}

export class ProfessionalClinic {
    clinic:                                             ProfessionalClinicClinic = new ProfessionalClinicClinic();
    collegiateNumber=                                   "";
}

export class ProfessionalClinicClinic {
    code=                                               "";
    name=                                               "";
    areaCode=                                           "";
    areaName=                                           "";
}

export interface MedicamentForTable {
    id:                         string;
    name?:                      string;
    composition?:               string;
    status:                     string;
    startDate:                  Date;
    endDate:                    Date;
    kind:                       string;
}
import { /* User,  */UserInfo } from '../../../models';
import { createReducer , on } from '@ngrx/store';
import { addUser, /* clearUser, */ removeUser /* , returnUsers */ } from '../../actions/users/user.actions';

export interface userState {
    User: UserInfo
}

export const initialUserEntries : UserInfo = {} ;

export const UserReducer = createReducer(
    initialUserEntries,
    //on(clearUser, _ => {}),

    on(addUser , (entries , User)=>{
        const entriesClone : UserInfo = User/* JSON.parse(JSON.stringify(entries)) */;
        return entriesClone;
    }),
    
    on(removeUser , (EmptyObject) => {
        const entriesClone : UserInfo = EmptyObject;
        return entriesClone;
    })
) 
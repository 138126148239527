import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterLink } from '@angular/router';
/* components */
import { AppComponent } from 'src/app/app.component';
/* Angular Material */
import { MatSliderModule } from '@angular/material/slider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SideNavComponent } from 'src/app/side-nav/side-nav.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
/* fontawsome */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
/* MSAL apis configs */
import * as msalConfigs from 'src/app/msal/apis.config';
import { 
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, 
  MsalRedirectComponent, MsalService 
} from '@azure/msal-angular';
import { AuthComponent } from './auth/auth.component';
/* ngrx */
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaEffects } from 'src/app/store/MetaEffects'; 
import { metaReducers, reducers } from 'src/app/store/Metareducers';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgOptimizedImage } from '@angular/common';
import { DxChartModule, DxDropDownBoxModule } from 'devextreme-angular';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

@NgModule({

  declarations: [
    AppComponent,
    SideNavComponent,
    AuthComponent
  ],
  
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatFormFieldModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    RouterLink,
    SharedModule,
    NgOptimizedImage,
    DxDropDownBoxModule,
    MatExpansionModule,
    /* msal */
    MsalModule,
    StoreModule.forRoot( reducers , { metaReducers } ),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
    }),
    EffectsModule.forRoot(metaEffects),
    StoreRouterConnectingModule.forRoot(),
    HttpClientModule,
    DxChartModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalConfigs.controlPanelMSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalConfigs.prescriptionsMSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalConfigs.MSALInterceptorConfigFactory
    },

    MsalService,
    MsalGuard,
    MsalBroadcastService
    
  ], 
  bootstrap: [AppComponent , MsalRedirectComponent]
})
export class AppModule { }

// platformBrowserDynamic().bootstrapModule(AppModule); ESTA LINEA HACE QUE LA SUSCRIPTION DE los parámetros se dispare 2 veces

import { Component, EventEmitter, Input, Output  } from '@angular/core';
/* fortAwesome */
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { IconDefinition , faCircleXmark ,faFilter ,faFileExcel } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss']
})

export class FilterInputComponent {

  value:                                                                  string="";
  faCircleXmark:                                                          IconDefinition=faCircleXmark;
  faFilter:                                                               IconDefinition=faFilter;
  faFileExcel:                                                            IconDefinition=faFileExcel;
  inputsDisabled=                                                         false;
  @Input()  PlaceHolder ? :                                               string ;
  @Output() InputValue :                                                  EventEmitter<string> = new EventEmitter;

  constructor(
    library:                                                              FaIconLibrary,
  ){
    library.addIcons(faCircleXmark);
    library.addIcons(faFilter);
  }

  StartTyping(e: KeyboardEvent): void {

    const target = e.target as HTMLInputElement;

    this.InputValue.emit(target.value);
  }

  ClearFilter( ):void{

    this.InputValue.emit("");

    this.value = "";
  }

}

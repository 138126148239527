import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of , /* EMPTY, */ merge } from 'rxjs';
import { catchError, mergeMap , exhaustMap, map, switchMap } from 'rxjs/operators';
import { AppsService } from 'src/app/app-actions/services';
import { UsersAppRolesMultiple , UserRoleAssignment , PermissionsAPIExceptions } from 'src/app/models';
import { ComponentToSpinnerService } from 'src/app/shared/services';
import * as permissionsActions from 'src/app/store/actions/permissions/permissions.actions';

@Injectable()

export class UsersInAppsEffects {

    constructor( 
        private action$ : Actions,
        private appsService : AppsService ,
        private componentToSpinnerService : ComponentToSpinnerService
    ){}

    /************************************************ FOR USERS *****************************************************/

    /* next effect did a http request per role id if you gave role array ["assadasd","asfasdf"] */
    /* loadUsers$ = createEffect(() => {
        return this.action$
        .pipe(

            ofType(permissionsActions.getUsersPerAppIdRoleId),

            exhaustMap(( action ) =>
            
                merge(
                    ...action.parameters.roleIds.map((id) =>

                        this.appsService.LoadUsersByAppIdAndRoleId({appId:action.parameters.appId , roleId:id}).pipe(

                            map(usersByAppRes => permissionsActions.getUsersPerAppIdRoleIdSuccess ({ usersByAppRes })),

                            catchError((error) =>

                                of( permissionsActions.getUsersPerAppIdRoleIdFailure({ error : error }) ),
                            ),
                        ),
                    ),
                ),
            ),
        ) 
    }) */


    loadUsers$ = createEffect(() => {

        return this.action$
        .pipe(

            ofType(permissionsActions.getUsersPerAppIdRoleId),
            switchMap(( action ) =>
            {
                return this.appsService.LoadUsersByAppIdAndRoleIdMultiple(action.parameters.appId , action.parameters.roleIds)
                .pipe(

                    map(( data ) =>{
                        
                        const usersByAppRes : UsersAppRolesMultiple[] = data.map(( rau : UsersAppRolesMultiple ) => {

                            const myRoleAssignments = rau.userRoleAssignments.map(( ra : UserRoleAssignment ) => {

                                ra.user.role = { roleId : "" , roleName : "" };
                                ra.user.roles = [{ roleId : "" , roleName : "" }];
                                
                                return ra;
                            })

                            return { ...rau , userRoleAssignments : myRoleAssignments };
                        })

                        return permissionsActions.getUsersPerAppIdRoleIdSuccess ({ usersByAppRes })

                    }),
                    catchError((error) =>
                        {
                            this.componentToSpinnerService.sendMessage('hide');
                            return of( permissionsActions.getUsersPerAppIdRoleIdFailure({ error : error }) )
                        }
                    ),
                )

            }),
        ) 
    })

    romoveUserPermissionInApp$ = createEffect(() => {

        return this.action$
        .pipe(

            ofType(permissionsActions.removeUserPermissionInApplication),

            mergeMap(( action ) => {

                return this.appsService.RemovePermissionInApp( action.roleToDelete )

                .pipe(

                    map(() =>{
                        
                        const { roleToDelete } = action;
                        
                        return permissionsActions.removeUserPermissionInApplicationSuccess({ roleToDelete });
                    }),
                        
                    catchError(error => {
                        
                        return of( permissionsActions.removeUserPermissionInApplicationFailure({ error : error }) )

                    })
                )
            })
        )
    })

    addUserPermissionInApp$ = createEffect(() => {

        return this.action$
        .pipe(

            ofType(permissionsActions.addUserToPermissionsList),

            mergeMap(( action ) => {

                const { roleToAdd } = action;

                return this.appsService.AddPermissionToAUserInApp( roleToAdd )

                .pipe(

                    map(( /* data */ ) =>{

                        return permissionsActions.addUserToPermissionsListSuccess({ roleToAdd });

                    }),

                    catchError(error => {

                        return of( permissionsActions.addUserToPermissionsListFailure({ error : error }) )

                    })
                )
            })
        )
    })

    /************************************************ FOR APPS *****************************************************/

    loadApps$ = createEffect(() => {

        return this.action$
        .pipe(

            ofType(permissionsActions.getAppsPerAppIdRoleId),
            switchMap(( action ) =>
            {
                return this.appsService.LoadUsersByAppIdAndRoleIdMultiple(action.parameters.appId , action.parameters.roleIds)
                .pipe(

                    map(( data ) =>{
                        const appsByAppRes : UsersAppRolesMultiple[] = data.map(( rau : UsersAppRolesMultiple ) => {

                            const myRoleAssignments = rau.userRoleAssignments.map(( ra : UserRoleAssignment ) => {
                                
                                ra.user.role = { roleId : "" , roleName : "" };
                                ra.user.roles = [{ roleId : "" , roleName : "" }];
                                
                                return ra;
                            })

                            return { ...rau , roleAssignments : myRoleAssignments };
                        })

                        return permissionsActions.getAppsPerAppIdRoleIdSuccess ({ appsByAppRes })

                    }),
                    catchError((error) =>
                        {
                            this.componentToSpinnerService.sendMessage('hide');
                            return of( permissionsActions.getUsersPerAppIdRoleIdFailure({ error : error }) )
                        }
                    ),
                )

            }),
        ) 
    })
}

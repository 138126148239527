import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { PrescriptionsStats } from "src/app/models";

export const getPrescriptionsStats = createAction(
    '[prescriptionsStats] Get prescriptions stats' , 
    props<{ parameters: { from : string , to : string } }>()
);

export const getPrescriptionsStatsSuccess = createAction(
    '[prescriptionsStats] Get prescriptions stats success' , 
    props<{ prescriptionsStats : PrescriptionsStats }>()
);

export const getPrescriptionsStatsFailure = createAction(
    '[prescriptionsStats] Get prescriptions stats failure' , 
    props<{ error : HttpErrorResponse }>()
);

/* export const getUsersPerAppIdRoleIdSuccess = createAction(
    '[AppRoles] Get users per appId and roleId success' ,
    props<{ usersByAppRes : UsersAppRolesMultiple[] }>()
)

export const getUsersPerAppIdRoleIdFailure = createAction(
    '[AppRoles] Get users per appId and roleId failure' ,
    props<{ error: HttpErrorResponse }>()
); */
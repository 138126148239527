<div>

    <div 
        class="form-filter_input_container" 
        [ngClass]="{ 
            'border-blue': value && value !== '' 
        }"   
    >
        <input
            appInputTrim 
            [(ngModel)]="value"
            type="text"
            class="input_nosubmit_white search_input"
            placeholder="{{ PlaceHolder }}"
            (keyup)="StartTyping($event)"
        >

        <fa-icon 
            *ngIf="value && value.length > 0  && !( inputsDisabled )"
            (click)="ClearFilter()" 
            class="form-x-icons" 
            [icon]="faCircleXmark"
        ></fa-icon>
    
    </div>

</div>

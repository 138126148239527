import { createReducer , on } from '@ngrx/store';
import { /* App, */ RoleToDelete } from 'src/app/models';
/* models */
/* ngrx */
import * as appRolesActions from 'src/app/store/actions/app/appRoles.actions';

export interface AppsState {
    roleToDelete : RoleToDelete;
}

const initialState : RoleToDelete = {};

export const RoleToDeleteReducer = createReducer(
    initialState,
    
    on( appRolesActions.storeRoleToDelete , (state , {role}  ) => {
        
        return role;
    })
)






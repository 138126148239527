
import { PermissionsAPIExceptions } from "../interfaces/permissionsApiExceptions.models";

export class UsersAppRolesMultiple {
    error ?:                             PermissionsAPIExceptions;
    status:                              string = 'initial';
    role:                                Role = new Role();
    userRoleAssignments  :               UserRoleAssignment[];
    applicationRoleAssignments  :        ApplicationRoleAssignments[];
    users ? :                            UserAppRole[] = [ new UserAppRole() ] ;
}

export class Role {
    id:                                  string = "";
    name:                                string = "";
    description:                         string = "";
    value:                               string = "";
    enabled:                             boolean;
    allowedMemberTypes:                  string[] = [""];
}

export class UserRoleAssignment {
    id:                                  string = "";
    user:                                UserAppRole;
}

export class ApplicationRoleAssignments {
    id:                                  string = "";
    application:                         ApplicationAppRole;
}

export class UserAppRole {
    principalId:                         string = "";
    email:                               string = "";
    fullName:                            string = "";
    userPrincipalName:                   string = "";
    roles :                              { roleId : string , roleName : string }[] = [ { roleId : '' , roleName : '' } ]           
    role :                               { roleId : string , roleName : string } = { roleId : "" , roleName : "" };
}

export class ApplicationAppRole {
    appId:                               string = '';
    resourceId:                          string = '';
    tenantId:                            string = '';
    name:                                string = '';
    replyUris:                           string[] = [''];
    secrets:                             null;
    bladeUri:                            string = '';
    iconUri:                             string = '';
    roles :                              { roleId : string , roleName : string }[] = [ { roleId : '' , roleName : '' } ]           
    role :                               { roleId : string , roleName : string } = { roleId : "" , roleName : "" };
}
import { Component, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
/* fontawsome */
import { 
  faTriangleExclamation, IconDefinition , faXmark ,faCircleCheck 
} from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { AlertContent } from 'src/app/models';
import { ComponentToAlertComponentService } from '../../services';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})

export class AlertComponent {

  subscription :                                                   Subscription;
  alert:                                                           AlertContent = {};
  faTriangleExclamation:                                           IconDefinition=faTriangleExclamation;
  faXmark:                                                         IconDefinition=faXmark;
  faCircleCheck:                                                   IconDefinition=faCircleCheck;            
  AlertaAbierta                                                    = false;
  @Output() CloseAlert :                                           EventEmitter<string> = new EventEmitter;

  constructor(

    library:                                                       FaIconLibrary,
    private ComponentToAlertComponentService :                     ComponentToAlertComponentService
  
  ){

    library.addIcons(faTriangleExclamation);
    library.addIcons(faXmark);
    library.addIcons(faCircleCheck);
    this.subscription = this.ComponentToAlertComponentService.onMessage().subscribe((message)=>{
      if(message){
        this.BuildTemplate(message);
      }
    })
    
  }

  BuildTemplate(alertInfo:AlertContent):void{
    this.AlertaAbierta=true;
    this.alert = alertInfo;
    /* setTimeout(() => {
      this.AlertaAbierta=false;
    }, 10000); */
  }

  OrderCloseAlert():void{
    this.AlertaAbierta=false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

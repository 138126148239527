import { Injectable } from '@angular/core';
import { PrescriptionsStats } from 'src/app/models';
import { StatsGroup } from 'src/app/models/controlPanel/classes/prescriptionsStats.models';
import { StatusSources, TodayResults, TodayResultsPerStatus } from 'src/app/models/controlPanel/classes/stats.models';

interface NumberPerStatusRes {
  arg : string , value : number 
}

interface StatusInfo {
  status: string;
  total: number;
  dailyValues: Record<string, number>;
}

interface StatusSource {
  value: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class PrescriptionsStatsHelperService {

  constructor() { }

  GetStatusResourcesDevexpressFormat( prescriptionsStats : PrescriptionsStats ):StatusSources[]{

    const { statsGroups } = prescriptionsStats || {};

    const myStatusSources : StatusSources[] = [] ;
    
    for (let i = 0; i < statsGroups.length; i++) {
      
      
      const { status } = statsGroups[i] || {};

      const statusColor : string = this.GetDraftColor( status );
      
      myStatusSources.push({ value:status , name:status , statusColor : statusColor });
    }

    return myStatusSources;

  }

  GetDaysStatusInfoDevexpressFormat( prescriptionsStats : PrescriptionsStats , statusSources : StatusSources[] ): ({day: string;} & Record<string, number>)[] {

    const { statsGroups } = prescriptionsStats || {};

    const primerConjunto: StatusInfo[] = [
      ...statsGroups
    ];
    
    const segundoConjunto: StatusSource[] = [
      ...statusSources
    ];

    const newArray: { day: string; [status: string]: string | number }[] = [];

    for (const { status, total, dailyValues } of primerConjunto) {

      for (const day in dailyValues) {

        if (dailyValues.hasOwnProperty(day)) {

          const statusSourceValue = segundoConjunto.find(item => item.value === status)?.name || 'Unknown';

          const formattedDay = new Date(day).toISOString().split('T')[0];
  
          const newItem = { day: formattedDay, [statusSourceValue]: dailyValues[day] };

          newArray.push(newItem);
        }
      }
    }

    const groupedData: Record<string, { day: string } & Record<string, number>> = {};

    newArray.forEach(item => {

      const { day, ...rest } = item;
    
      if (!groupedData[day]) {

        groupedData[day] = { day, ...rest } as { day: string } & Record<string, number>;

      } else {

        Object.entries(rest).forEach(([status, value]) => {

          groupedData[day][status] = (groupedData[day][status] || 0) + Number(value);

        });
      }
    });
    const resultArray = Object.values(groupedData);

    return resultArray;
  }

  GetPrescriptionNumberPerStatus( prescriptionsStats : PrescriptionsStats ): NumberPerStatusRes[] {
  
    const { statsGroups } = prescriptionsStats || {};
  
    const numberPerStatus : NumberPerStatusRes[] = statsGroups.map(( sg : StatsGroup ) => {
 
      return { arg : sg.status , value : sg.total };

    }) 

    return numberPerStatus;

  }

  GetTodayPrescriptionsGroupedByPrescriptionsStates( prescriptionsStats : PrescriptionsStats ):TodayResults{

    const { statsGroups } = prescriptionsStats || {};

    const today = new Date().toISOString().split('T')[0];

    const todayResults : TodayResults = new TodayResults();

    for ( let i = 0; i < statsGroups.length; i++ ) {

      const { dailyValues , status , total } = statsGroups[i] || {};

      const resultPerStatus : TodayResultsPerStatus = { total : 0 , status : status };

      const dailyValuesKeys = Object.keys(dailyValues);

      const dailyValuesVals = Object.values(dailyValues);

      for ( let x = 0; x < dailyValuesKeys.length; x++ ) {
        
        if( dailyValuesKeys[x].split('T')[0] === today /* '2024-01-16' */ ){
          
          resultPerStatus.total = dailyValuesVals[x];

          todayResults.total = todayResults.total + dailyValuesVals[x]; 
        }
      }

      todayResults.todayResultsPerStatus.push( resultPerStatus );
    }

    return todayResults;
  }

  GetDraftColor( status : string ):string{
  
    let hexaColor : string = "";

    switch (status) {
      case 'Draft':
        hexaColor = '#a6911d';
        break;
      case 'Pending':
        hexaColor = '#57acfd';
        break;
      case 'Emitted':
        hexaColor = '#33b05d';
        break;
      case 'Canceled':
        hexaColor = '#e94141';
        break;
      case 'Expired':
        hexaColor = '#853e3e';
        break;
      case 'Finished':
        hexaColor = '#124f26';
        break;
      case 'RetryDraft':
        hexaColor = '#f19337';
        break;
      case 'Manual':
        hexaColor = '#6DCED0';
        break;
      case 'MasterFormula':
        hexaColor = '#1D6686';
        break;
      default: hexaColor = '#a6911d'
        break;
    }

    return hexaColor;
  }
}

/* customs */
export { ComponentToDeletePopUpService } from 'src/app/shared/services/customs/component-to-delete-pop-up.service';
export { ComponentToSpinnerService } from 'src/app/shared/services/customs/component-to-spinner.service';

/* httpRequests */
export { ProfessionalsService } from 'src/app/shared/services/httpRequets/professionals.service';

/* alertComponentService */
export { ComponentToAlertComponentService } from 'src/app/shared/services/helpers/component-to-alert-component.service';

/* helpers */
export { AppsHelperService } from 'src/app/shared/services/helpers/apps-helper.service';
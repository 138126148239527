import { Injectable } from '@angular/core';
import { MasterFormula, Medicament, MedicamentForTable, ParapharmacyMedicament } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})

export class PrescriptionsHelperService {

  constructor() { }

  ExtractMedicamentsAllKind( medicaments?:Medicament[] , parapharmacyMedicaments?:ParapharmacyMedicament[] , masterFormulas?:MasterFormula[] ):MedicamentForTable[]{

    let myMedicamentsAllKind : MedicamentForTable[] = [];

    if(medicaments){

      const myMedicaments : MedicamentForTable[] = medicaments.map(( m : Medicament ) => {

        return { id : m.id , name : m.name , endDate : m.endDate , startDate : m.startDate , status : m.status , kind:'medicament' };
      })

      myMedicamentsAllKind = [ ...myMedicamentsAllKind , ...myMedicaments ];
    
    } 
    
    if ( masterFormulas ){

      const myMasterFormulas : MedicamentForTable[] = masterFormulas.map(( m : MasterFormula ) => {

        return { id : m.id , name : m.composition , endDate : m.endDate , startDate : m.startDate , status : m.status , kind:'masterFormula' };
      })

      myMedicamentsAllKind = [ ...myMedicamentsAllKind , ...myMasterFormulas ];
    
    }
    
    if ( parapharmacyMedicaments ){

      const myParapharmacies : MedicamentForTable[] = parapharmacyMedicaments.map(( m : ParapharmacyMedicament ) => {

        return { id : m.id , name : m.name , endDate : m.endDate , startDate : m.startDate , status : m.status , kind:'paraMedicament'};
      })

      myMedicamentsAllKind = [ ...myMedicamentsAllKind , ...myParapharmacies ];
    }

    return myMedicamentsAllKind;
  }
}

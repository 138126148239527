import { createReducer , on } from "@ngrx/store";
import { PrescriptionsStats } from "src/app/models";
import * as prescriptionsStatsActions from 'src/app/store/actions/stats/prescriptonsStats.actions';

export interface PrescriptonsStatsAppState {
    prescriptonsStats : PrescriptionsStats;
}

const initialState : PrescriptionsStats = new PrescriptionsStats();

export const PrescriptionsStatsReducer = createReducer(
    initialState,

    on( prescriptionsStatsActions.getPrescriptionsStatsSuccess , (state , {prescriptionsStats}) => {

        state = {...prescriptionsStats , status : 'first_assignement'};

        return state;
        
    }) , 

    on( prescriptionsStatsActions.getPrescriptionsStatsFailure , (state , {error}) => {

        return state;
        
    })
)
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { PrescriptionsStatsService } from 'src/app/app-stats/services';
// import { AppsMetricsService } from 'src/app/app-actions/services/httpRequests/apps-metrics.service';
import * as prescriptionsStatsActions from 'src/app/store/actions/stats/prescriptonsStats.actions';
/* rxjs */
/* import { of } from 'rxjs';
import { catchError , concatMap, exhaustMap, map, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppsService } from 'src/app/app-actions/services';
import * as appRolesActions from 'src/app/store/actions/app/appRoles.actions'; */

@Injectable()

export class StatsEffects {

    constructor( 
        private action$ : Actions,
        private prescriptionsStatsService : PrescriptionsStatsService
    ){}

    LoadPrescriptionsStats$ = createEffect(() => {

        return this.action$
        .pipe(

            ofType(prescriptionsStatsActions.getPrescriptionsStats),

            mergeMap(( action ) => {

                const { parameters } = action;

                return this.prescriptionsStatsService.LoadPrescriptionsMetrics( parameters )

                .pipe(

                    map(( data ) =>{

                        const prescriptionsStats = data;
                        
                        return prescriptionsStatsActions.getPrescriptionsStatsSuccess({ prescriptionsStats });

                    }),

                    catchError(error => {

                        return of( prescriptionsStatsActions.getPrescriptionsStatsFailure({ error : error }) )

                    })
                )
            })
        )
    })
}
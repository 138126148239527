import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AlertContent } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class ComponentToAlertComponentService {

  private subject = new Subject<AlertContent>();

  sendMessage( message : AlertContent ) {
    this.subject.next( message );
  }

  clearMessages() {
    this.subject.next({});
  }

  onMessage(): Observable<AlertContent> {
    return this.subject.asObservable();
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { PermissionsAPIExceptions, RoleToDelete , UsersAppRoles, UsersAppRolesMultiple } from 'src/app/models';
import { RoleToAdd } from 'src/app/models/controlPanel/interfaces/appRoles.models';

/************************************************ FOR USERS *****************************************************/

export const getUsersPerAppIdRoleId = createAction(
    '[AppRoles] Get users per appId and roleId' , 
    props<{ parameters: { roleIds : string[] , appId : string } }>()
);

export const getUsersPerAppIdRoleIdSuccess = createAction(
    '[AppRoles] Get users per appId and roleId success' ,
    props<{ usersByAppRes : UsersAppRolesMultiple[] }>()
)

export const getUsersPerAppIdRoleIdFailure = createAction(
    '[AppRoles] Get users per appId and roleId failure' ,
    props<{ error: PermissionsAPIExceptions }>()
);

export const addUserToPermissionsList = createAction(
    '[AppRoles] Add user to permissions list',
    props<{ roleToAdd : RoleToAdd }>()
)

export const addUserToPermissionsListSuccess = createAction(
    '[AppRoles] Add user to permissions list success',
    props<{ roleToAdd : RoleToAdd }>()
)

export const addUserToPermissionsListFailure = createAction(
    '[AppRoles] Add user to permissions list failure',
    props<{ error : PermissionsAPIExceptions }>()
)

export const cleanUserAppRolesState = createAction(
    '[AppRoles] Clean users assignements state',
    props<{ cleanedState : object }>()
)

export const cleanAppsAppRolesState = createAction(
    '[AppRoles] Clean apps assignements state',
    props<{ cleanedState : object }>()
)

export const removeUserPermissionInApplication = createAction(
    '[AppRoles] Remove user permission in application',
    props<{ roleToDelete : RoleToDelete }>()
)

export const removeUserPermissionInApplicationSuccess = createAction(
    '[AppRoles] Remove user permission in application success',
    props<{ roleToDelete : RoleToDelete }>()
)

export const removeUserPermissionInApplicationFailure = createAction(
    '[AppRoles] Remove user permission in application failure',
    props<{ error : PermissionsAPIExceptions }>()
)

/************************************************ FOR APPS *****************************************************/

export const getAppsPerAppIdRoleId = createAction(
    '[AppRoles] Get apps per appId and roleId' , 
    props<{ parameters: { roleIds : string[] , appId : string } }>()
);

export const getAppsPerAppIdRoleIdSuccess = createAction(
    '[AppRoles] Get apps per appId and roleId success' ,
    props<{ appsByAppRes : UsersAppRolesMultiple[] }>()
)

export const getAppsPerAppIdRoleIdFailure = createAction(
    '[AppRoles] Get apps per appId and roleId failure' ,
    props<{ error: HttpErrorResponse }>()
);
import { createReducer , on } from '@ngrx/store';
import { UsersGroupsFilters } from 'src/app/models';
import { modifyUsersGroupsFilters } from '../../actions/usersGroups/usersGroupsFilters.actions';

export interface UsersGroupsFiltersState {
    usersGroups : UsersGroupsFilters;
}

const initialState : UsersGroupsFilters = new UsersGroupsFilters();

export const UsersGroupsFiltersReducer = createReducer(
    initialState,

    on(modifyUsersGroupsFilters , (state , {usersGroupsFilters}  ) => {
        state = usersGroupsFilters;
        return state;
    })
)
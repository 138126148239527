import { createReducer, on } from "@ngrx/store";
import { UsersAppRolesMultiple } from "src/app/models";
import { UserRoleAssignment } from "src/app/models/controlPanel/classes/usersAppRolesMultiple.models";
import * as permissionsActions from 'src/app/store/actions/permissions/permissions.actions';

export interface UsersInAppState {
    usersInApp : UsersAppRolesMultiple[]
}

const initialState : UsersAppRolesMultiple[] = [ new UsersAppRolesMultiple() ]

export const PermissionsUserPerAppReducer = createReducer(
    initialState,

    on( permissionsActions.getUsersPerAppIdRoleIdSuccess , (state , {usersByAppRes}) => {

        return usersByAppRes.map((ur : UsersAppRolesMultiple) => {

            return { ...ur , status : 'first_assignement' };
        });
        
    }),

    on( permissionsActions.getUsersPerAppIdRoleIdFailure , (state , { error }) => {
        
        const myUsersAppRolesMultiple = new UsersAppRolesMultiple();

        myUsersAppRolesMultiple.error = error;

        const newState : UsersAppRolesMultiple[] = [myUsersAppRolesMultiple];
        
        return newState;
    }),
    
    on( permissionsActions.addUserToPermissionsListSuccess , (state , {roleToAdd}) =>{

        const { roleId , email , principalId , objectId } = roleToAdd || {};

        return state.map(( uar : UsersAppRolesMultiple ) => {
            
            if( uar.role.id === roleId ){

                if( roleId && email && principalId && objectId ){
                    
                    const myRoleAssignment : UserRoleAssignment = {
                        id : "",
                        user : {
                            principalId            : principalId,
                            email                  : email,
                            fullName               : "",
                            userPrincipalName      : "",
                            roles                  : [ { roleId : '' , roleName : '' } ],           
                            role                   : { roleId : roleId , roleName : "" }
                        }
                    }
    
                    return { ...uar , userRoleAssignments : [ myRoleAssignment , ...uar.userRoleAssignments ] }

                } else {

                    return uar;

                }

            } else {

                return uar;

            }
        
        })
    }),

    on( permissionsActions.addUserToPermissionsListFailure , ( state , {error} ) => {
        
        const itemWithError : UsersAppRolesMultiple = new UsersAppRolesMultiple();

        itemWithError.error = error;
        
        const newState = [ itemWithError , ...state ];

        return newState;
    }),

    on( permissionsActions.removeUserPermissionInApplicationSuccess , (state , {roleToDelete}  ) => {
        
        const { roleId , principalId } = roleToDelete || {};

        return state.map(( uar ) => {
            
            if( uar.role.id === roleId ){

                const myNewRoleAssignments : UserRoleAssignment[] = uar.userRoleAssignments.filter(( ra ) => {
                    return ra.user.principalId !== principalId
                } );

                
                return { ...uar , userRoleAssignments : myNewRoleAssignments } ;

            } else {

                return uar;

            }
            
        })
    }),

    on ( permissionsActions.removeUserPermissionInApplicationFailure , (state , { error }) => {

        const itemWithError : UsersAppRolesMultiple = new UsersAppRolesMultiple();

        itemWithError.error = error;
        
        const newState = [ itemWithError , ...state ];

        return newState;
    }),

    on( permissionsActions.cleanUserAppRolesState , (state , {cleanedState}  ) => {
        
        const myState : UsersAppRolesMultiple[] = [ new UsersAppRolesMultiple() ];

        return  myState ;
        
    }),

    











    /* on( permissionsActions.getUsersPerAppIdRoleIdSuccess , (state , {usersByAppRes}  ) => {

        let myState;

        if( state.role )
        {

            if(usersByAppRes.users){

                const usersByAppResWithRoleId = { 
                    ...usersByAppRes , 
                    users : usersByAppRes.users.map((u) => {

                        if(usersByAppRes.role){

                            return { ...u , roleId : usersByAppRes.role.id }

                        }else {

                            return u;

                        }
                         
                    })
                }

                const myUsers = state.users ? state.users.concat(usersByAppResWithRoleId.users) : state.users;
                myState = { ...usersByAppRes , users : myUsers };
            }
            
        } 
        else
        {
            if(usersByAppRes.users){
                const myAppUsers = usersByAppRes.users.map((u) => { 

                    return usersByAppRes.role ? { ...u , roleId : usersByAppRes.role.id } : u; 
                });
                myState = { ...usersByAppRes , users : myAppUsers };
            }
        }


        return   myState ? myState : state ;
        
    }),

    on( permissionsActions.removeUserPermissionInApplicationSuccess , (state , {roleToDelete}  ) => {
        const { roleId , principalId } = roleToDelete || {};
        
        let myNewState;

        if( state.users ){
            const myFilteredUsers = state.users.filter((u) => !( u.roleId === roleId && u.principalId === principalId )) ;
            myNewState = { ...state , users : myFilteredUsers }
            
        }
        return myNewState ? myNewState : state;
        
    }),

    on( permissionsActions.cleanUserAppRolesState , (state , {cleanedState}  ) => {
        return  cleanedState ;
        
    }),

    on( permissionsActions.addUserToPermissionsListSuccess , (state , {roleToAdd}) =>{
        const myOriginalRoles = state.users ? [...state.users] : [];
        const finalRolesList = [roleToAdd].concat(myOriginalRoles);
        const finalState = { ...state , users : finalRolesList };
        return finalState;
    }) */
)

import { HttpErrorResponse } from "@angular/common/http"
import { createAction, props } from "@ngrx/store"
import { UserGroup } from "src/app/models/controlPanel/classes/userGroups.models"

export const getUsersGroups = createAction(
    '[Users Groups] Get Users groups',
    props<{ order : string }>()
)

export const getUsersGroupsSuccess = createAction(
    '[Users Groups] Get Users groups success',
    props<{ userGroups : UserGroup[] }>()  
)

export const getUsersGroupsFailure = createAction(
    '[Users Groups] Get Users groups failure',
    props<{ error : HttpErrorResponse }>()  
)
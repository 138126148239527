import { createAction, props } from "@ngrx/store"
import { UsersAsync } from "src/app/models"

export const getUsersFiltered = createAction(
    '[Users] Get Users',
    props<{ term : string }>()
)

export const getUsersSuccess = createAction(
    '[Users] Get Users success',
    props<{ users : UsersAsync[] }>()  
)

export const addUser = createAction(
    '[Users] Add user',
    props<{ user : UsersAsync }>()
)

export const addUserSuccess = createAction(
    '[Users] Add user success',
    props<{ user : UsersAsync }>()
)
import { createReducer , on } from '@ngrx/store';
/* models */
import { AppRole } from 'src/app/models';
/* ngrx */
import * as AppRolesActions from 'src/app/store/actions/app/appRoles.actions';

export interface AppRolesState {
    appRoles : AppRole[];
}

const initialState : AppRole[] = []

export const AppsRolesReducer = createReducer(
    initialState,

    on(AppRolesActions.storeRoles , (state , { appRoles }) => {
        return appRoles
    }) , 

    on(AppRolesActions.cleanRolesState , (state , { cleanedState }) => {
        return [];
    })
    
    /* on( AppRolesActions.getAppsRolesSuccess , (state , {appRoles}  ) => {
        return appRoles;
    }),

    on( AppRolesActions.getAppsRolesFailure , (state , {error}  ) => {
        
       return [error]
        
    }) */
)
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject , map, takeUntil } from 'rxjs';
import { App, AppState } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class AppsHelperService implements OnDestroy {

  private destroy$:                                                 Subject<void> = new Subject<void>();
  private appDestroyed$:                                            Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute, private store: Store<AppState>) {}

  CheckParams(): Observable<string | null> {

    return this.route.queryParamMap.pipe(

      takeUntil(this.destroy$),map((queryParam) => queryParam.get('objectId') || null)
    );
  }

  GetAppByObjectId( objectId : string ):Observable< App >{

    return this.store.select( AppState => AppState.App ).pipe( takeUntil( this.appDestroyed$ ),
      map(
        ( apps : App[] ) => apps.filter((a) => a.resourceId === objectId)[0]
      )
    )
  }

  ngOnDestroy(): void {

    this.destroy$.next();
    this.destroy$.complete();
    this.appDestroyed$.next();
    this.appDestroyed$.complete();
  }
}

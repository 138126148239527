import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
  {
    path:'', redirectTo : 'appActions' , pathMatch:'full'
  },
  {
    path:'appActions',
    loadChildren:()=> import('./app-actions/app-actions.module').then(m=>m.AppActionsModule),
    canActivate:[MsalGuard]
  },
  {
    path:'users',
    loadChildren:()=> import('./users/users.module').then(m=>m.UsersModule),
    canActivate:[MsalGuard]
  },
  {
    path:'usersGroups',
    loadChildren:()=> import('./users-groups/users-groups.module').then(m=>m.UsersGroupsModule),
    canActivate:[MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes , {
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' 
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

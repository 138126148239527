export const environment = {
    production: false,

    envName:'dev',
    resourceGroupId:'cc5e1d6a-7f6f-45b0-8d4a-8ba85d322c32',

    redirectUri: window.location.hostname === 'localhost' ? 'http://localhost:4200' : 'https://control-panel-dev.clinicabaviera.com/',
    logoutRedirectUri: window.location.hostname === 'localhost' ? 'http://localhost:4200' : 'https://control-panel-dev.clinicabaviera.com',

    /******************************************* CONTROL PANEL ***************************************************/  
    controlPanelService:{

        scopes:[
            'profile',
            'openid',
            'be0d26ce-8b00-442b-9da6-776a32403742/control_panel'
        ],

        authority: 'https://login.microsoftonline.com/cb4bec43-26c0-4837-bf6c-ab98d284af38',
        clientId:  'be0d26ce-8b00-442b-9da6-776a32403742'
    },

    usersService:{
        url: window.location.hostname === 'localhost' ? /* 'http://localhost:7172/api/' */ 'https://app-users-dev-westeu.azurewebsites.net/api/' : 'https://app-users-dev-westeu.azurewebsites.net/api/',
        scopes:[
            '05a35cc1-faee-44e0-b1e4-0cff3f341f0d/users',
            'profile',
            'openid'
        ],

        authority: 'https://login.microsoftonline.com/cb4bec43-26c0-4837-bf6c-ab98d284af38',
        clientId: '05a35cc1-faee-44e0-b1e4-0cff3f341f0d'
    },

    permissionsService:{

        url: window.location.hostname === 'localhost' ? 'https://app-permissions-dev-westeurope.azurewebsites.net/api/' : 'https://app-permissions-pro-westeurope.azurewebsites.net/api/',
        scopes:[
            'profile',
            'openid',
            'aa98a6d0-5834-42e9-8eaf-8fc0539af611/permissions'
        ],
        clientId: 'aa98a6d0-5834-42e9-8eaf-8fc0539af611',
     
    },

    prescriptionsService:{
        url: window.location.hostname === 'localhost' ? 'https://app-prescriptions-dev-westeu.azurewebsites.net/api/' : 'https://app-prescriptions-pro-westeu.azurewebsites.net/api/',
        scopes:[
            'profile',
            'openid' , 
            '9b2c420a-19cb-4d68-8213-8cf55a2f5358/prescriptions'
        ],

        clientId: '9b2c420a-19cb-4d68-8213-8cf55a2f5358', 
        authority: 'https://login.microsoftonline.com/cb4bec43-26c0-4837-bf6c-ab98d284af38',
    }, 
};

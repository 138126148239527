<div class="top_menu-main_container" >
      
   <div class="top_menu-container" >

      <div class="top_menu-tittle_span_container" >
         <span class="top_menu-tittle_span" >
            {{ currentApplication }}
         </span>
      </div>

      <div class="top_menu-ids_container" >
         <div>
            <span>
               <strong> Application Id </strong> : {{ applicationId }}
            </span>
         </div>
   
         <div>
            <span>
               <strong> Object Id </strong> : {{ objectId }}
            </span>
         </div>
      </div>

   </div>
      
   <div class="top_menu-nav_container" >
      <nav class="c-menu">

         <a 
            class="c-menu__link" 
            routerLink="/appActions/permissions" 
            [queryParams]="{ currentApplication: currentApplication , applicationId : applicationId , objectId : objectId }"
            [routerLinkActiveOptions]="{ exact: false }" 
            routerLinkActive="c-menu__link--active"
         >
            Permisos
         </a>

         <a 
            *ngIf="currentApplication === 'Cb.Prescriptions'"
            class="c-menu__link" 
            routerLink="/appActions/search" 
            [queryParams]="{ currentApplication: currentApplication , applicationId : applicationId , objectId : objectId }"
            [routerLinkActiveOptions]="{ exact: false }" 
            routerLinkActive="c-menu__link--active"
         >
            Busqueda
         </a>

         <a 
            *ngIf="currentApplication === 'Cb.Prescriptions'"
            class="c-menu__link" 
            routerLink="/appActions/relatedServices" 
            [queryParams]="{ currentApplication: currentApplication , applicationId : applicationId , objectId : objectId }"
            [routerLinkActiveOptions]="{ exact: false }" 
            routerLinkActive="c-menu__link--active"
         >
            Servicios
         </a>

         <a 
            *ngIf="currentApplication === 'Cb.Prescriptions'"
            class="c-menu__link" 
            routerLink="/appActions/status" 
            [queryParams]="{ currentApplication: currentApplication , applicationId : applicationId , objectId : objectId  }" 
            routerLinkActive="c-menu__link--active"
            [routerLinkActiveOptions]="{ exact: false }"
         >
            Status
         </a>
         
      </nav>
   </div>

</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UsersAsync } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http :                                       HttpClient
  ) { }

  LoadUsers(term:string):Observable<UsersAsync[]>{
    const myTerm : string = term ? term : 'a';
    return this.http.get<UsersAsync[]>(`${environment.usersService.url}users?term=${myTerm}`);
  }

  AddNewUser( user : UsersAsync ):Observable<string>{
    return this.http.post<string>(`${environment.usersService.url}users` , user);
  }
}

export class UsersAsync {
    principalId='';
    fullName ? ='';
    email='';
    mail='';
    documentNumber='';
    firstName='';
    lastName='';
    principalName='';
    displayName=       '';
    givenName=         '';
    surname=           '';
    mailNickname=      '';
    userPrincipalName= '';
    mobilePhone=       '';
    preferredLanguage= '';
    accountEnabled=    false;
    creationType=      '';
    profilePhotoUrl=   '';
}
<div  *ngIf="AlertaAbierta" >
    <div  
        [ngStyle]="{
            'background-color': alert.color ,
            'border': alert.borderColor ,
            'border-radius' : '10px' , 
            'padding' : '10px 10px',
            'display' : 'flex',
            'justify-content' : 'space-between',
            'margin-bottom' : '10px'
        }" 
        class="alert-main_container" 
    >
        <div>
            <div class="alert-title_span_container" >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                <span class="alert-title_span" > {{alert.title}} </span>
            </div>
            <div class="alert-content_span_container">
                <span class="alert-content_span"> {{alert.content}} </span>
            </div>
        </div>
        
        <div>
            <div>
                <fa-icon class="alert-x_button" (click)="OrderCloseAlert()" [icon]="faXmark"></fa-icon>
            </div>
        </div>
    </div>
</div>
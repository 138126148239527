import { createReducer, on } from "@ngrx/store";
import { UsersAppRolesMultiple } from "src/app/models";
import * as permissionsActions from 'src/app/store/actions/permissions/permissions.actions';

export interface UsersInAppState {
    usersInApp : UsersAppRolesMultiple[]
}

const initialState : UsersAppRolesMultiple[] = [ new UsersAppRolesMultiple() ]

export const PermissionsAppsPerAppReducer = createReducer(
    initialState,

    on( permissionsActions.getAppsPerAppIdRoleIdSuccess , (state , {appsByAppRes}) => {

        return appsByAppRes.map((ur : UsersAppRolesMultiple) => {

            return { ...ur , status : 'first_assignement' };
        });
        
    }) , 
    
    on( permissionsActions.cleanAppsAppRolesState , (state , {cleanedState}  ) => {
        
        const myState : UsersAppRolesMultiple[] = [ new UsersAppRolesMultiple() ];

        return  myState ;
        
    }),
)


    












import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/models';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalsService {

  constructor(
    private http :                                HttpClient
  ) { }

  LoadUsers(term:string):Observable<User[]>{
    const myTerm : string = term ? term : 'a';
    return this.http.get<User[]>(`${environment.permissionsService.url}users?term=${myTerm}`);
  }
}

export class TodayResults {

    todayDate : string = FormatPrescriptionDateDayMonthYear( new Date().toString() );
    total : number = 0;
    todayResultsPerStatus : TodayResultsPerStatus[] = [];
}

export class TodayResultsPerStatus {
    status: string = "";
    total : number = 0;
}

export class StatusSources{
    statusColor: string = "";
    value : string = "";
    name : string = ""
}

/* export class myDaysStatusInfoDevexpressFormat{

} */

function FormatPrescriptionDateDayMonthYear(date:string):string{
    const dateObj  = new Date(date);
    const month = dateObj.getMonth() + 1;

    const day = dateObj.getDate() < 10 ? '0' + dateObj.getDate() : dateObj.getDate();

    const year = dateObj.getFullYear();
    return day + "/" + month + "/" + year;
}
import { createReducer , on } from "@ngrx/store";
import { PrescriptionResponse } from "src/app/models";
import * as prescriptionsActions from 'src/app/store/actions/search/prescriptions.actions';

export interface PrescriptionAppState {
    prescriptonsStats : PrescriptionResponse;
}

const initialState : PrescriptionResponse = new PrescriptionResponse();

export const PrescriptionReducer = createReducer(

    initialState,

    on( prescriptionsActions.getPrescriptionByIdSuccess , (state , {prescription}) => {

        state = prescription;

        return state;
        
    }) , 

    on( prescriptionsActions.getPrescriptionByIdFailure , (state , {error}) => {

        return { ...state , error : error };
        
    }),

    on( prescriptionsActions.cleanPrescriptionById , ( state , { order } ) => {
        return new PrescriptionResponse();
    })
)
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of  } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PrescriptionResponse } from 'src/app/models';
import { PrescriptionsService } from 'src/app/searcher/services';
import * as prescriptionsActions from 'src/app/store/actions/search/prescriptions.actions';

@Injectable()

export class PrescriptionsEffects {

    constructor( 
        private action$ : Actions,
        private prescriptionService : PrescriptionsService
    ){}

    /************************************************ FOR APPS *****************************************************/

    loadPrescription$ = createEffect(() => {

        return this.action$

        .pipe(

            ofType(prescriptionsActions.getPrescriptionById),

            switchMap(( action ) =>
            {
                const { prescriptionId } = action || {};

                return this.prescriptionService.LoadPrescriptionById( prescriptionId )

                .pipe(

                    map(( prescription : PrescriptionResponse ) =>{

                        return prescriptionsActions.getPrescriptionByIdSuccess ({ prescription })

                    }),

                    catchError((error) =>
                        {
                            return of( prescriptionsActions.getPrescriptionByIdFailure({ error : error }) )
                        }
                    ),
                )
            }),
        ) 
    })
}
